import * as z from 'zod';

declare global {
  interface Window {
    API_URL: string;
    SENTRY_DSN: string;
  }
}

const DEFAULT_SENTRY_DSN =
  'https://806615fc2f401378f6e8d8eb593b1ea7@o4506909085270016.ingest.us.sentry.io/4507539393216512';

const RUNTIME_ENV_VARS = {
  API_URL: window.API_URL || 'https://api.scored.gg',
  SENTRY_DSN: window.SENTRY_DSN || '',
};

const createEnv = () => {
  const EnvSchema = z.object({
    API_URL: z.string().default('https://api.scored.gg'),
    SENTRY_DSN: z.string().default(DEFAULT_SENTRY_DSN),
    ENABLE_API_MOCKING: z
      .string()
      .refine((s) => s === 'true' || s === 'false')
      .transform((s) => s === 'true')
      .optional(),
    APP_URL: z.string().optional().default('https://app.scored.gg'),
    APP_MOCK_API_PORT: z.string().optional().default('8080'),
  });

  const viteEnvVars = Object.entries(import.meta.env).reduce<
    Record<string, string>
  >((acc, curr) => {
    const [key, value] = curr;
    if (key.startsWith('VITE_APP_')) {
      acc[key.replace('VITE_APP_', '')] = value;
    }
    return acc;
  }, {});

  const envVars = Object.entries(RUNTIME_ENV_VARS).reduce<
    Record<string, string>
  >((acc, [key, value]) => {
    if (value.length > 0 && !value.startsWith('<!--#echo')) {
      acc[key] = value;
    }
    return acc;
  }, viteEnvVars);

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided. The following variables are missing or invalid:
      ${Object.entries(parsedEnv.error.flatten().fieldErrors)
        .map(([k, v]) => `- ${k}: ${v}`)
        .join('\n')}`,
    );
  }
  return parsedEnv.data;
};

export const env = createEnv();
